import { AxiosError } from "axios";

import warning from "tiny-warning";

export const isAxiosError = (err: any): err is AxiosError => {
  return typeof err === "object" && err.isAxiosError;
};

export const getErrorMessage = (error: AxiosError): string => {
  const isArray = Array.isArray(error?.response?.data?.errors);
  const isString = typeof error?.response?.data?.error === "string";

  warning(
    isArray || isString,
    `Expected error.response.data.error to be of type string, got "${typeof error
      ?.response?.data?.error}"`
  );

  if (isArray) {
    const errorArray = error.response.data.errors;
    const errors = Array.isArray(errorArray[0])
      ? errorArray[0][0]
      : errorArray[0];
    if (typeof errors === "string") {
      return errors;
    } else if (Array.isArray(errors?.description?.errors)) {
      return errors?.description?.errors[0].message ?? "";
    } else {
      return errors?.description ?? "";
    }
  }

  if (isString) {
    return error.response.data.error;
  }

  return "";
};

export const getGroupedFieldErrors = (err: any) => {
  const errors = err?.response?.data?.errors || err?.response?.data?.error;

  if (Array.isArray(errors)) {
    const errorFieldArray = Array.isArray(errors[0]) ? errors[0] : errors;
    return errorFieldArray.reduce((errObj, { field, description }) => {
      field = field || "unknown_error";
      return {
        ...errObj,
        [field]: errObj[field]
          ? `${errObj[field]}\n${description}`
          : description,
      };
    }, {});
  } else {
    const field = errors?.field || "unknown_error";
    const description =
      errors?.description ||
      (typeof errors === "string" ? errors : "An unknown error occurred");

    return { [field]: description };
  }
};
