import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["loadingIndicator", "runIcon"];

  connect() {
    // Default state
    const unitId = this.element.getAttribute("data-unit-id");

    this.data.set("sceneId", this.element.getAttribute("data-id"));
    this.data.set("unitId", unitId);

    if (unitId) {
      // Join websocket channel for this device
      this.channel = this.application.websocketManager.joinChannel(
        `scenes:${unitId}`
      );
    }
  }

  runScene() {
    // Wait for scene to finish running before running it again.
    if (this.running === true) {
      return;
    }

    this.running = true;

    if (this.channel) {
      this.channel
        .push("run", { scene_id: this.data.get("sceneId") })
        .receive("ok", () => this.resetLoadingAfterDelay());
    }
  }

  clickOptions(event) {
    this.running = false;
    event.stopPropagation();
  }

  resetLoadingAfterDelay() {
    // Wait 5 seconds for scene to run (hopefully!)
    setTimeout(() => {
      this.running = false;
    }, 5000);
  }

  set running(running) {
    this.data.set("running", running);
    if (running) {
      this.loadingIndicatorTarget.classList.remove("u-hidden-visually");
      this.runIconTarget.classList.add("u-hidden-visually");
    } else {
      this.loadingIndicatorTarget.classList.add("u-hidden-visually");
      this.runIconTarget.classList.remove("u-hidden-visually");
    }
  }

  get running() {
    return this.data.get("running");
  }
}
