import "mdn-polyfills/NodeList.prototype.forEach"; // jquery fails in Edge15

import { initSentry } from "./lib/sentry";
import { initWebsocketManager } from "./lib/socket-manager";

import "../scss/app.scss";
import "flatpickr/dist/flatpickr.min.css";
require("phoenix_html");

initSentry();
initWebsocketManager();

require("./stimulus/main");
require("./react/routers/unauthenticated");
