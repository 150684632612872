import * as Sentry from "@sentry/react";

import { isAxiosError } from "@/react/lib/axios-helpers";

const IGNORED_ERROR_CODES = [400, 401, 403, 404, 409, 422];

export function initSentry() {
  if (window.sentryDsn) {
    Sentry.init({
      dsn: window.sentryDsn,
      environment: window.sentryEnv,
      release: process.env.SENTRY_RELEASE,
      ignoreErrors: [
        "ResizeObserver loop limit exceeded",
        "TypeError: a[b].target.className.indexOf is not a function",
        "ReferenceError: 'Promise' is undefined",
        "SecurityError: Blocked a frame with origin",
        "Loading chunk",
        "Route not found: ",
        "The operation is insecure.",
        "is not defined",
        "ResizeObserver loop completed with undelivered notifications.",
        "msDiscoverChatAvailable",
      ],
      beforeSend: (event, hint) => {
        // filter out axios errors that have a response status code
        if (
          hint?.originalException &&
          isAxiosError(hint.originalException) &&
          !!hint.originalException.response?.status &&
          IGNORED_ERROR_CODES.includes(hint.originalException.response?.status)
        ) {
          return null;
        }
        return event;
      },
    });
  }
}
