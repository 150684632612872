import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    this.clickCounter = 0;
  }

  actionClick(e) {
    if (e.currentTarget.dataset.dontDisable) {
      return;
    }
    this.clickCounter = this.clickCounter + 1;

    if (this.clickCounter > 1) {
      e.currentTarget.setAttribute("disabled", "disabled");
    }
  }
}
