import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    // Default state
    const unitId = this.element.getAttribute("data-unit-id");
    this.data.set("sceneId", this.element.getAttribute("data-id"));

    // Join websocket channel for this device
    this.channel = this.application.websocketManager.joinChannel(
      `scenes:${unitId}`
    );
  }

  runScene() {
    this.channel.push("run", { scene_id: this.data.get("sceneId") });
  }
}
