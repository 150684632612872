import { Socket as PhxSocket, Channel } from "phoenix";

import { instance } from "@/react/hooks/api";
import { isAxiosError } from "@/react/lib/axios-helpers";

interface WebsocketTokenResponse {
  token: string;
}

class Socket extends PhxSocket {
  protected params: Record<string, any> | (() => Record<string, any>);

  async connect() {
    if ((this as any).unloaded) {
      return;
    }

    try {
      const { data: params } = await instance({
        apiNamespace: "authentication",
      }).post<WebsocketTokenResponse>("/websocket-token");

      this.params = () => ({
        token: params.token,
        app_version: `cmw-${process.env.SENTRY_RELEASE}`,
      });
    } catch (err) {
      if (isAxiosError(err) && err.response?.status === 401) {
        // If the API access token is expired, this error probably is not recoverable,
        // so we should stop retrying.
        return;
      }

      // Rethrow the error or return early to stop trying to reconnect.
      (this as any).reconnectTimer.scheduleTimeout();
      return;
    }

    super.connect();
  }
}

export class WebsocketManager {
  channels: Record<string, Channel> = {};
  socket: Socket;

  constructor() {
    // Connect to the socket
    this.socket = new Socket("/socket");

    if (window.websocketAccessToken) {
      this.socket.connect();
    }
  }

  joinChannel(channelName: string, payload = {}) {
    let channel = this.channels[channelName];

    if (!channel) {
      // Create a new channel and join it.
      channel = this.socket.channel(channelName, payload);

      this.socket.onError((error) => {
        console.error("Socket Error", error);
      });

      this.socket.onClose(() => {
        console.warn("Socket closed");
      });

      channel.join();

      this.channels[channelName] = channel;
    }

    return channel;
  }
}

export function initWebsocketManager() {
  const websocketManager = new WebsocketManager();
  window.SmartRent = {
    socket: websocketManager,
  };
  return websocketManager;
}
